<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      About
      <router-link slot="right" to="/dashboard" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <notification class="is-info">
        <div>
          <b-icon icon="buffer" custom-size="default" />
          To be used to provide&nbsp;<b>meaningful information.</b>
        </div>
      </notification>

      <card-component
        class="has-table has-mobile-sort-spaced"
        title="About"
        icon="account-multiple"
      >
        <section class="section">
        <h1 class="title">Information</h1>
        <h2 class="subtitle">
            A simple container to divide your page into <strong>sections</strong>, like the one you're currently reading.
        </h2>
        </section>
      </card-component>

    </section>
  </div>
</template>

<script>
import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
export default {
  name: 'About',
  components: {
    HeroBar,
    TitleBar,
    CardComponent,
    Notification
  },
  computed: {
    titleStack () {
      return ['Admin', 'About']
    }
  }
}
</script>
