<template>
  <card-component title="Change Password" icon="lock">
    <form @submit.prevent="submit">
      <b-field
        horizontal
        label="Current password"
        message="Required. Your current password"
      >
        <b-input
          v-model="form.password_current"
          name="password_current"
          type="password"
          required
          autcomplete="current-password"
        />
      </b-field>
      <hr />
      <b-field horizontal label="New password" message="Required. New password">
        <b-input
          v-model="form.password"
          name="password"
          type="password"
          required
          autocomplete="new-password"
        />
      </b-field>
      <b-field
        horizontal
        label="Confirm password"
        message="Required. New password one more time"
      >
        <b-input
          v-model="form.password_confirmation"
          name="password_confirmation"
          type="password"
          required
          autocomplete="new-password"
        />
      </b-field>
      <hr />
      <b-field horizontal>
        <div class="control">
          <button
            type="submit"
            class="button is-primary"
            :class="{ 'is-loading': isLoading }"
          >
            Submit
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import { mapActions } from 'vuex'
import { apiService } from '@/services'
export default {
  name: 'PasswordUpdateForm',
  components: {
    CardComponent
  },
  data () {
    return {
      isLoading: false,
      form: this.getPassUpdateForm()
    }
  },
  methods: {
    ...mapActions({ showError: 'error/show' }),
    getPassUpdateForm () {
      return {
        email: this.$store.state.userEmail,
        password_current: null,
        password: null,
        password_confirmation: null
      }
    },
    submit () {
      this.isLoading = true
      this.form.email = this.$store.state.userEmail
      apiService.postAll('/user/changePassword', this.form).then(response => {
        this.isLoading = false
        this.form = this.getPassUpdateForm()
        this.$buefy.snackbar.open({
          message: response.data,
          queue: false
        }, 500)
      }).catch(error => {
        this.isLoading = false
        this.showError(error)
      })
    }
  }
}
</script>
