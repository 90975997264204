<template>
  <card-component title="Edit Profile" icon="account-circle">
    <form @submit.prevent="submit">
      <b-field horizontal label="Avatar">
        <file-picker @input="setFile" />
      </b-field>
      <hr />
      <b-field horizontal label="Name" message="Required. Your name">
        <b-input v-model="form.name" name="name" required />
      </b-field>
      <b-field horizontal label="E-mail" message="To change this email contact admin.">
        <b-input disabled v-model="form.email" name="email" type="email" required />
      </b-field>
      <hr />
      <b-field horizontal>
        <div class="control">
          <button
            type="submit"
            class="button is-primary"
            :disabled="isLoading"
            :class="{ 'is-loading': isLoading }"
          >
            Submit
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import { mapState } from 'vuex'
import FilePicker from '@/components/FilePicker'
import CardComponent from '@/components/CardComponent'
import { apiService } from '@/services'
import helper from '@/mixins/helper'

export default {
  name: 'ProfileUpdateForm',
  mixins: [helper],
  components: {
    CardComponent,
    FilePicker
  },
  data () {
    return {
      isFileUploaded: false,
      isLoading: false,
      form: {
        avatar: null,
        name: null,
        email: null
      }
    }
  },
  computed: {
    ...mapState(['userName', 'userEmail', 'userAvatar'])
  },
  watch: {
    userName (newValue) {
      this.form.name = newValue
    },
    userEmail (newValue) {
      this.form.email = newValue
    },
    userAvatar (newValue) {
      this.form.avatar = newValue
    }
  },
  mounted () {
    this.form.name = this.userName
    this.form.email = this.userEmail
  },
  methods: {
    setFile (fileName) {
      this.form.avatar = fileName
    },
    submit () {
      this.isLoading = true
      this.clean(this.form)
      apiService.postWithFile('/user/updateProfile', this.form).then(response => {
        this.isLoading = false
        this.$store.commit('user', {
          name: response.data.name,
          email: response.data.email,
          avatar: response.data.avatar
        })
        this.$buefy.snackbar.open({
          message: 'Profile updated successfully.',
          queue: false
        }, 500)
      }).catch(error => {
        this.isLoading = false
        this.showError(error)
      })
    }
  }
}
</script>
